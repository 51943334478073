<template>
     <div class="modal fade preview-modal full-on-mobile" id="modalAnamnesa" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between pt-4 pb-0 border-0">
              <div class="d-flex align-items-center">
                  <span class="modal-titlee text-active fm-poppins fst-normal fs-24 lh-36 mb-0">
                    Form Anamnesa
                  </span>
              </div>
            </div>
            <div class="modal-body py-3 px-3">
              <Form
                id="anamnesa"
                class="pb-4"
                @submit="submit"
                v-slot="{ errors }"
              >

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Keluhan / Diagnosa <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_keluahan"
                    rules="required"
                    name="keluhan"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.keluhan }"
                    placeholder="Masukkan keluhan anda" />
                    <ErrorMessage name="keluhan" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Jam Makan Terakhir <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_jam_makan_terakhir"
                    rules="required"
                    name="jamMakan"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.jamMakan }"
                    placeholder="00:00" />
                    <ErrorMessage name="jamMakan" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Obat Yang Dikonsumsi <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_obat_yg_dikonsumsi"
                    rules="required"
                    name="obat"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.obat }"
                    placeholder="Masukkan Nama Obat" />
                    <ErrorMessage name="obat" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Riwayat Penyakit <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_riwayat_penyakit"
                    rules="required"
                    name="penyakit"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.penyakit }"
                    placeholder="Riwayat penyakit" />
                    <ErrorMessage name="penyakit" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Riwayat Alergi <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_riwayat_alergi"
                    rules="required"
                    name="alergi"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.alergi }"
                    placeholder="Riwayat alergi" />
                    <ErrorMessage name="alergi" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Penyakit Keluarga <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <Field
                    type="text"
                    v-model="cart.anamnesa_penyakit_keluarga"
                    rules="required"
                    name="penyakitKeluarga"
                    class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                    :class="{ 'is-invalid w-100': errors.penyakitKeluarga }"
                    placeholder="Penyakit keluarga" />
                    <ErrorMessage name="penyakitKeluarga" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>

                <label class="form-label fm-nunito fst-normal fw-bold fs-14 lh-19">
                  Apakah Anda Merokok? <span class="text-danger">*</span>
                </label>
                <div class="input-group d-flex flex-column mb-3">
                    <select
                      class="form-select text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0 w-100"
                      :class="{ 'is-invalid w-100': errors.rokok }"
                      v-model="cart.anamnesa_kebiasaan_merokok"
                      rules="required"
                      name="rokok"
                    >
                      <option value="TIDAK" selected>Tidak</option>
                      <option value="YA">Ya</option>
                    </select>
                    <ErrorMessage name="rokok" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
                </div>
              </Form>
            </div>
            <div class="modal-footer">
              <div class="d-flex w-100 gap-3">
                <button type="button" class="btn btn-light w-100" data-bs-dismiss="modal" ref="closeModal">
                    <p class="fm-poppins fst-normal fs-14 lh-21 mb-0">Batalkan</p>
                </button>
                <button form="anamnesa" type="submit" class="btn btn-primary w-100">
                    <p class="text-white fm-poppins fst-normal fs-14 lh-21 mb-0">Simpan</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { ref } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { cart } from '@/core/cart'

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  props: {
    statusAnamnesa: {
      type: Boolean,
      required: true
    }
  },
  setup (props, context) {
    const closeModal = ref(null)
    const submit = () => {
      closeModal.value.click()
      context.emit('update:statusAnamnesa', true)
    }

    return {
      submit,
      cart,
      closeModal
    }
  }
}
</script>

<style scoped>
.input-group .form-control {
  border-radius: 8px !important;
  width: 17rem;
}

.form-control, .form-select {
  border-radius: 8px !important;
}

.btn-gender {
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #FF9D0A;
  color: #FFFFFF
}

.modal-content {
  @media (min-width: 520px) {
    height: 90vh;
  }
}

input[type="radio"] {
  display: none;
  position: absolute;
}

.form-check {
  padding-left: 0px;
}

.form-select {
  background-color: #EFF0F7;
}

.btn-light {
  border: 1px solid #666;
  color: #666;
}
.btn-light:hover {
  background-color: #666;
  color: #fff;
}
</style>
