<template>
  <div class="modal full-on-mobile fade show" id="modalAddPatient" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header row justify-content-between py-md-4 border-0">
          <div class="col-md-6">
            <div class="d-flex align-items-center">
              <span class="modal-titlee text-active fm-poppins fst-normal fs-24 lh-36 mb-0">
                {{ $route.query.addPatient ? 'Tambah Pasien' : 'Pilih Data Pasien' }}
              </span>
              <button
                class="d-none d-md-block btn btn-secondary ms-3"
                @click="$router.push({
                  query: !$route.query.addPatient ? {
                    ...$route.query,
                    addPatient: true
                  } : { modalPatient: 'show' }
                })">
                <div
                  v-if="!$route.query.addPatient" class="d-flex align-items-center">
                  <i class="ri-add-circle-line me-1"></i>
                  <span>Tambah</span>
                </div>

                <div v-else class="d-flex align-items-center">
                  <i class="ri-close-circle-line me-1"></i>
                  <span>Batal</span>
                </div>
              </button>
            </div>
          </div>
          <div
            class="col-md-4"
            :class="{
              'd-none d-md-block': $route.query.addPatient
            }">
            <div class="d-flex align-items-center justify-content-end">
              <div
                v-if="!$route.query.addPatient"
                class="input-group mt-3 mt-md-0">
                <input
                  type="text"
                  class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
                  placeholder="Cari pasien"
                  name="search"
                  v-model="keyword"
                  autocomplete="off">
              </div>
              <div
                class="ms-3 d-none d-md-block">
                <router-link
                  type="button"
                  id="btn-close-modal"
                  class="btn text-primary fm-poppins fst-normal fs-12"
                  :to="{ query: {} }">
                  Tutup
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body py-md-3 px-3 py-sm-4 px-sm-4">
          <div v-if="!$route.query.addPatient" class="row">
            <div
              v-for="member in filteredMembers"
              :key="member"
              class="col-md-4 col-12 p-2"
            >
              <div
                @click="selectMember(member)"
                class="card card-member"
                :class="{
                  active: tempSelectedMember?.pas_id === member.pas_id
                }">
                <div class="card-body">
                  <h5 class="card-title">{{ member.anggota_nama }}</h5>
                  <hr class="d-none d-md-block dropdown-divider">
                  <p class="card-text">{{ member.anggota_email_user }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <form-add-patient
              @added="onFormAdded" />
          </div>
          <router-link
            v-if="!$route.query.addPatient && !tempSelectedMember"
            :to="{
              query: !$route.query.addPatient ? ({ ...$route.query, addPatient: true }) : {}
            }"
            class="floating-button d-flex d-md-none btn btn-primary text-white">
            <i class="ri-add-line"></i>
          </router-link>
        </div>
        <div
          v-if="!$route.query.addPatient && tempSelectedMember"
          class="modal-footer">
          <button
            class="btn btn-primary btn-apply"
            @click="applySelectedMember">
            Pilih Pasien
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import FormAddPatient from './FormAddPatient'
import { user, members, registerNewMember, getMembers } from '@/core/auth'
import { selectedMember } from '@/core/service'
import { cart } from '@/core/cart'
import { useRouter } from 'vue-router'

export default {
  components: {
    FormAddPatient
  },
  setup () {
    // Ambil list anggota
    onMounted(() => {
      getMembers({
        pas_id_pendaftar: user.value.pas_id,
        id_pendaftar: user.value.id
      })
    })

    const router = useRouter()
    const tempSelectedMember = ref(null)
    const selectMember = payload => {
      // toggle
      if (tempSelectedMember.value?.pas_id === payload.pas_id) {
        tempSelectedMember.value = null
      } else {
        tempSelectedMember.value = payload
      }
    }
    const applySelectedMember = () => {
      cart.value.status_periksa = 2
      selectedMember.nama = tempSelectedMember.value.anggota_nama
      selectedMember.pas_id_periksa = tempSelectedMember.value.pas_id
      router.push({
        query: {}
      })
    }
    const onFormAdded = () => {
      // Tutup formnya dan ambil lagi datanya
      router.push({
        query: {
          modalPatient: 'show'
        }
      })
      getMembers({
        pas_id_pendaftar: user.value.pas_id,
        id_pendaftar: user.value.id
      })
    }

    const keyword = ref(null)
    const filteredMembers = computed(() => {
      return members.value?.filter(
        _member => _member
          .anggota_nama
          ?.toLowerCase()
          ?.search(keyword.value?.toLowerCase()) > -1 || _member
            .anggota_email_user
            ?.toLowerCase()
            ?.search(keyword.value?.toLowerCase()) > -1 ||
            !keyword.value
      )
    })

    return {
      registerNewMember,
      members,
      selectMember,
      onFormAdded,
      tempSelectedMember,
      applySelectedMember,
      keyword,
      filteredMembers
    }
  }
}
</script>

<style lang="scss" scoped>
.card-service {
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 10px !important;
  width: 8rem;
}

.modal-content {
  @media (min-width: 523px) {
    height: 90vh;
  }
}

.btn-apply {
  @media (max-width: 523px) {
    width: 100%;
  }
}

.input-group {
  @media (max-width: 520px) {
    width: 100%;
  }

  .form-control {
    border-radius: 8px !important;
    width: 100%;

    @media (min-width: 520px) {
      width: 17rem;
    }
  }
}

.card-member {
  cursor: pointer;

  @media (min-width: 520px) {
    text-align: center;
  }

  &.bg-secondary {
    background-color: unset;
  }

  &:hover {
    @media (min-width: 520px) {
      color: white;
      background-color: #8CC63F;
    }
  }

  &.active {
    color: white;
    background-color: #8CC63F;
  }
}

.floating-button {
  width: 3rem;
  height: 3rem;
  flex: none;
  display: block;
  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

</style>
