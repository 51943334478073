<template>
<Form
    class="col-md-6 mx-auto pb-4"
    @submit="submit"
    v-slot="{ errors }"
    enctype="multipart/form-data"
  >

    <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Nama
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="text"
        v-model="form.nama"
        name="nama"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.nama }"
        placeholder="Nama Lengkap" />
        <ErrorMessage name="nama" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div>

    <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Email
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="email"
        v-model="form.email"
        name="email"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.email }"
        placeholder="Email Aktif" />
        <ErrorMessage name="email" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div>

    <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Tanggal Lahir
    </label>
    <div class="input-group mb-3">
        <Field
        type="date"
        v-model="form.tgllahir"
        name="tgllahir"
        rules="required"
        class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.tgllahir }"
        placeholder="Tanggal Lahir" />
        <ErrorMessage name="tgllahir" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div>

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      No. KTP
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="text"
        v-model="form.noktp"
        name="noktp"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.noktp }"
        placeholder="No. KTP" />
        <ErrorMessage name="noktp" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">Foto KTP</label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="file"
        @change="handleImage($event, 'ktp')"
        name="ktp"
        accept="image/*"
        rules="required"
        class="form-control w-100 bg-grayscale text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.ktp }"
        placeholder="No. KTP" />
        <ErrorMessage name="ktp" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">Pas Foto</label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="file"
        @change="handleImage($event, 'foto')"
        name="foto"
        rules="required"
        accept="image/*"
        class="form-control w-100 bg-grayscale text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.foto }"
        placeholder="No. KTP" />
        <ErrorMessage name="foto" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      No. Telepon / WA
    </label>
    <div class="input-group mb-3">
        <Field
        type="no_hp"
        v-model="form.no_hp"
        name="no_hp"
        rules="required"
        class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.no_hp }"
        placeholder="Nomor telepon aktif" />
        <ErrorMessage name="no_hp" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div>

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      No. Whatsapp
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="text"
        v-model="form.nowa"
        name="nowa"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.nowa }"
        placeholder="Nomor whatsapp aktif" />
        <ErrorMessage name="nowa" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Alamat
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <textarea
        v-model="form.alamat"
        name="alamat"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.alamat }"
        placeholder="Alamat" />
        <ErrorMessage name="alamat" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <!-- <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Kota
    </label>
    <div class="input-group d-flex flex-column mb-3">
        <Field
        type="text"
        v-model="form.kota"
        name="kota"
        rules="required"
        class="form-control w-100 bg-grayscale text-placeholder fm-nunito fst-normal fs-12 lh-16 p-3 border-0"
        :class="{ 'is-invalid w-100': errors.kota }"
        placeholder="Kota" />
        <ErrorMessage name="kota" class="invalid-feedback text-error fm-nunito fs-12 fw-600" />
    </div> -->

    <label class="form-label text-gray-3 fm-nunito fst-normal fw-bold fs-14 lh-19">
      Jenis Kelamin
    </label>
    <div class="d-flex justify-content-between mb-4">
      <div class="w-50">
          <div class="form-check pe-2">
              <input
              v-model="form.jenkelamin"
              name="jenkelamin"
              class="form-check-input"
              type="radio"
              value="L">
              <div
                @click="form.jenkelamin='L'"
                class="btn btn-gender ps-3 pe-2 py-2 d-flex justify-content-between align-items-center"
                :class="{ active: form.jenkelamin === 'L'}"
              >
              <label class="fm-nunito fst-normal fs-12 lh-16 mb-0 pt-1" >
                Laki-laki
              </label>
                  <div class="box-icon">
                      <i class="ri-men-line icon-gender"></i>
                  </div>
              </div>
          </div>
      </div>
      <div class="w-50">
          <div class="form-check ps-2 d-flex justify-content-end">
              <input
              v-model="form.jenkelamin"
              class="form-check-input"
              type="radio"
              name="jenkelamin"
              value="P">
              <div @click="form.jenkelamin='P'" class="btn btn-gender ps-3 pe-2 py-2 d-flex justify-content-between align-items-center" :class="{ active: form.jenkelamin === 'P'}">
                  <p class="fm-nunito fst-normal fs-12 lh-16 mb-0 pt-1">
                    Perempuan
                  </p>
                  <div class="box-icon">
                      <i class="ri-women-line icon-gender"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="text-center">
      <span class="text-error fm-nunito fs-16 fw-600">{{ errorMessage }}</span>
      <button
        type="submit"
        class="btn btn-primary w-100 py-3 fm-poppins fst-normal fs-14 lh-21 mb-0"
        :disabled="loadingAddPatient">
         {{ loadingAddPatient ? 'Menyimpan..' : 'Simpan' }}
      </button>
    </div>
  </Form>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import { ref } from '@vue/reactivity'
import { user, registerNewMember } from '@/core/auth'

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup (props, { emit }) {
    const fd = new FormData()
    const errorMessage = ref('')
    const loadingAddPatient = ref(false)
    const form = ref({
      nama: null,
      email: null,
      tgllahir: null,
      noktp: null,
      no_hp: null,
      nowa: null,
      alamat: null,
      kota: null,
      jenkelamin: 'L',
      pasien_kode: user.value.pas_id,
      pasien_id: user.value.id
    })

    const handleImage = (input, type) => {
      const file = input.target.files[0] || input.dataTransfer.files[0]
      if (!file) {
        return
      }

      fd.append(type, file, file.name)
    }

    const submit = async () => {
      try {
        loadingAddPatient.value = true
        // Reset pesan errornya dulu
        errorMessage.value = ''

        // Append object ke formData
        Object.keys(form.value).forEach(key => fd.append(key, form.value[key]))
        const result = await registerNewMember(fd)

        if (!result.status) {
          errorMessage.value = result.message
        }

        emit('added')
      } catch (error) {
        console.error('INI ERROR = ', error)
        errorMessage.value = error.response.data.message
      } finally {
        loadingAddPatient.value = false
      }
    }

    return {
      form,
      submit,
      handleImage,
      errorMessage,
      loadingAddPatient
    }
  }
}
</script>

<style scoped>
.btn-gender {
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #FF9D0A;
  color: #FFFFFF
}

input[type="radio"] {
  display: none;
  position: absolute;
}

.form-check {
  padding-left: 0px;
}
</style>
